.counter {
  display: flex;
  align-items: center;
  border: 1px var(--white) solid;
  border-radius: 25px;
  padding: 6px 15px;
  box-sizing: border-box;
  max-width: 170px;
  &__input {
    background: none;
    border: none;
    color: var(--mk-white);
    font-size: 36px;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }
  &__btn {
    background: none;
    color: var(--white);
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
}
