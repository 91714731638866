@import "../../styles/mixins/index.scss";

.footer {
  background-image: url("../../assets/images/intro/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 0 40px;
  @include lg {
    padding: 40px 0 40px;
  }
  &__title {
    margin-bottom: 8px;
  }
  &__social {
    display: flex;
    align-items: center;
    gap: 26px;
    margin: 20px 0 40px;
    a {
      color: var(--white);
    }
    @include md {
      justify-content: center;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    @include md {
      flex-direction: column;
      text-align: center;
    }
    @include sm {
    }
  }
  &__wrap-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding-bottom: 40px;
    border-bottom: solid 1px var(--gray);
    gap: 20px;
    @include md {
      flex-direction: column;
    }
  }
  &__menu {
    display: flex;
    gap: 45px;
    @include lg {
      gap: 10px;
    }
    @include sm {
      flex-direction: column;
    }
    a {
      color: var(--white);
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  &__input {
    color: var(--gray);
    padding: 12px 34px;
    width: 100%;
    &-wrap {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 24px;
      display: flex;
      align-items: center;
      max-width: 310px;
      width: 100%;
      @include md {
        margin: auto;
      }
    }
    &-btn {
      text-transform: uppercase;
      background-color: var(--green);
      padding: 12px 23px;
      border-radius: 24px;
      font-weight: 700;
    }
  }
  &__copyright {
    display: flex;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
    }
  }
}
