@import "../../styles/mixins/index.scss";

.collapse {
  position: relative;
  color: var(--white);
  border-radius: 40px;
  transition: 2s background-color;
  cursor: pointer;
  &_active {
    background-color: rgba(0, 0, 0, 0.5);
    .collapse--icon {
      transform: rotate(180deg);
    }
  }
  &_faq {
    background: none;
  }
  &--title {
    margin-top: 0;
    margin-bottom: 8px;
    background: linear-gradient(210deg, #7900da 0%, #ae46ff 100%);
    border-radius: 38px;
    padding: 10px 10px 12px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include md {
      font-size: 20px;
    }
    &_wrap {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &_faq {
      background: none;
      padding: 10px 10px 12px;
      justify-content: flex-start;
      gap: 12px;
      border-radius: 0;
      .collapse--title_wrap {
        color: var(--green);
      }
    }
  }

  &--id {
    font-size: 36px;
  }
  &--desc {
    margin-bottom: 0;
    max-height: 0;

    overflow: hidden;
    transition: max-height 0.3s;
    &_active {
      padding-bottom: 24px;
      overflow: initial;
      opacity: 1;
      max-height: 1200px;
    }
    & > ul {
      list-style-type: disc;
      padding: 24px 28px 0 48px;
      li {
        list-style-type: initial;
        margin-bottom: 20px;
      }
    }
    div {
      padding: 0px 34px;
      & > hr {
        border-width: 2px;
      }
    }
    & > p {
      padding: 0px 34px;
      margin-bottom: 10px;
      a {
        color: var(--white);
        text-decoration: underline;
        margin-left: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &_faq {
      padding: 0 28px 0 85px;
    }
  }
  &--icon {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(270deg, #7900da 0%, #ae46ff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
  }
}
