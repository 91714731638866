@import "../../styles/mixins/index.scss";

.intro {
  background-image: url("../../assets/images/intro/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &:before {
    background: linear-gradient(180deg, transparent, #070517);
    bottom: 0;
    content: "";
    height: 200px;
    position: absolute;
    width: 100%;
  }
}

.block {
  padding-block-start: 80px;
  padding-block-end: 64px;
  position: relative;
  @include md {
    padding-block-start: 40px;
    padding-block-end: 40px;
    text-align: center;
  }
  &__sub-title {
    margin-bottom: 22px;
  }
  &__text {
    margin-bottom: 20px;
    font-size: 24px;
    @include md {
      margin-bottom: 20px;
    }
  }
}

.social {
  position: fixed;
  z-index: 100;
  right: 2%;
  bottom: 50%;
  transform: translateY(50%);
  color: var(--black);
  background-color: var(--white);
  border-radius: 30px;
  padding: 22px 16px;
  display: inline-flex;
  gap: 26px;
  align-items: center;
  flex-direction: column;
  @include md {
    position: initial;
    flex-direction: row;
    padding: 12px 22px;
  }
  a {
    color: var(--black);
    transition: 0.3s;
    &:hover {
      color: var(--green);
    }
  }
}

.dopeplants {
  background-color: #350959;
  background-image: url("../../assets/images/dope/bg.png");
  background-repeat: no-repeat;
  background-position: right;
  &__desc {
    background: rgba(0, 0, 0, 0.5);
    padding: 92px 0;
    font-size: 24px;
    font-weight: 700;
    @include md {
      text-align: center;
      padding: 40px 0;
    }
    @include sm {
      font-size: 18px;
    }
  }
  &__wrap-slider {
    display: flex;
    justify-content: space-between;
    gap: 120px;
    align-items: flex-start;
    @include lg {
      flex-direction: column;
      gap: 20px;
    }
    p {
      max-width: 500px;
      @include xl {
        max-width: 400px;
      }
      @include lg {
        max-width: 100%;
      }
    }
  }
  &__wrap {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    gap: 20px;
    @include lg {
      flex-direction: column;
    }
  }
  &__block {
    max-width: 580px;
    @include lg {
      max-width: initial;
    }
    @include md {
      text-align: center;
      button {
        margin: auto;
      }
    }
    @include sm {
      font-size: 18px;
    }
    &:last-child {
      p {
        margin-bottom: 26px;
      }
    }
  }
  &__title {
    margin-bottom: 20px;
  }
}

.carousel {
  background-image: url("../../assets/images/carousel/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block-start: 140px;
  padding-block-end: 64px;
  @include md {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
}

.about {
  background-image: url("../../assets/images/intro/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 85px 0;
  position: relative;
  @include md {
    padding: 40px 0;
  }
  &:before {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
  }
  &__img {
    width: 400px;
    height: 400px;
  }
  &__title {
    margin-bottom: 14px;
    @include md {
      margin-bottom: 20px;
    }
  }
  &__text {
    font-size: 24px;
    font-weight: 700;
    @include sm {
      font-size: 18px;
    }
    p {
      margin-bottom: 14px;
      &:last-child {
        margin: 32px 0;
      }
    }
  }
  &__wrap {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    gap: 40px;
    @include lg {
      flex-direction: column;
    }
  }
  &__btns {
    display: flex;
    gap: 32px;
    @include md {
      gap: 20px;
    }
    @include xs {
      flex-direction: column;
      a {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.partners {
  background-color: #300850;
  padding-block-start: 62px;
  padding-block-end: 120px;
  text-align: center;
  position: relative;
  @include md {
    padding-block-end: 80px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 17.4%, #000000 100%);
    transform: rotate(180deg);
  }
  &__desc {
    margin-bottom: 60px;
  }
  &__wrap {
    display: flex;
    justify-content: center;
    gap: 100px;
    @include sm {
      flex-direction: column;
      gap: 30px;
    }
  }
  &__block {
    width: 190px;
    font-size: 22px;
    text-transform: uppercase;
    @include sm {
      width: 100%;
    }
  }
  &__img {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    height: 190px;
    background-color: var(--black-sec);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
  }
  &__btn {
    font-size: 14px;
    padding: 6px 10px;
    text-transform: lowercase;
  }
}

.roadmap {
  position: relative;
  background-color: #300850;
  // background-image: url("../../assets/images/roadmap/roadmap.png");
  background-position: left;
  background-size: 800px;
  background-repeat: no-repeat;
  padding-block-start: 100px;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgb(48, 8, 80) 17.4%, #470979 100%);
  }
  &__soon {
    margin-top: 72px;
    font-size: 60px;
    line-height: 72px;
    color: var(--white);
  }
  @include lg {
    background-image: none;
  }
  &__title {
    @include md {
      text-align: center;
    }
  }
  &__block {
    // max-width: 740px;
    margin-left: auto;
    padding-bottom: 10px;
    @include lg {
      max-width: initial;
      margin-left: initial;
    }
    @include sm {
      min-height: initial;
      h3 {
        text-align: center;
      }
    }
  }
  &__wrap {
    margin-top: 48px;
  }
  &__desc {
    font-size: 24px;
    @include md {
      text-align: center;
    }
    @include sm {
      font-size: 18px;
    }
  }
}

.meet {
  background-color: #300850;
  background-image: url("../../assets/images/meet/bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  min-height: 400px;
  padding: 134px 0 90px;
  @include lg {
    padding: 40px 0 40px;
  }
  @include md {
    text-align: center;
  }
  &__title {
    margin-bottom: 14px;
  }
  &__bg {
    background: rgba(0, 0, 0, 0.5);
  }
  &__img {
    height: 400px;
    @include md {
      margin: auto;
    }
    @include sm {
      height: auto;
    }
  }
  &__wrap {
    padding: 120px 0;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    @include lg {
      flex-direction: column;
      padding: 40px 0;
    }
  }
  &-block {
    max-width: 820px;
    &__text {
      font-size: 24px;
      @include sm {
        font-size: 18px;
      }
    }
    &__btns {
      display: flex;
      gap: 32px;
      @include md {
        gap: 20px;
        justify-content: center;
      }
      @include xs {
        flex-direction: column;
        justify-content: center;
      }
      a {
        @include xs {
          display: flex;
          justify-content: center;
        }
      }
    }
    &__join {
      margin: 62px 0 26px;
    }
  }
}

.faq {
  background-color: #300850;
  background-image: url("../../assets/images/faq/bg.png");
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: right center;
  padding-bottom: 120px;
  @include lg {
    padding-bottom: 40px;
  }
  @include sm {
    background-image: none;
  }
  &__block {
    max-width: 632px;
  }
}
