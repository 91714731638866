@import "../../styles/mixins/index.scss";

.slider {
  width: 100%;
  &__img {
    img {
      width: 100%;
    }
  }
  &__wrap {
    position: relative;
    overflow: hidden;
    &:hover {
      .slider__desc {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &__desc {
    opacity: 0;
    transform: translateY(200px);
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #00000096;
    transition: .3s transform, .6s opacity ease-in-out;
    @include sm {
      bottom: 6px;
    }
    h4 {
      font-size: 20px;
      @include sm {
        font-size: 22px;
      }
    }
    p {
      font-size: 14px;
      line-height: 18px;
      @include sm {
        font-size: 16px;
      }
    }
  }
}

.btns {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 36px;
  @include lg {
    margin-top: 6px;
    justify-content: center;
  }
  button {
    background: linear-gradient(270deg, #7900da 0%, #ae46ff 100%);
    border-radius: 50%;
    color: var(--white);
    font-weight: 700;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.9;
    }
  }
}
