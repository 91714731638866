.form {
  &__group {
    margin-bottom: 20px;
    width: 100%;
  }

  &__input {
    background-color: var(--dark-green);
    font-family: "Nunito", Arial;
    color: var(--white);
    padding: 18px 10px;
    border-radius: 12px;
    font-family: "Raleway", Arial;
    width: 100%;
    border: solid 1px var(--white);
    margin-bottom: 4px;
    &::placeholder {
      color: var(--gray);
    }
  }
  &__msg {
    background-color: var(--dark-green);
    color: var(--gray);
    padding: 20px 40px;
    border-radius: 20px;
    border: none;
    resize: vertical;
    font-family: "Raleway", Arial;
    width: 100%;
  }
  &__btn {
    font-family: "Nunito", Arial;
    background-color: var(--white);
    padding: 20px;
    border-radius: 12px;
    text-transform: uppercase;
    color: var(--black);
    font-size: 16px;
    font-weight: 800;
    width: 100%;
    transition: 0.2s;
    position: relative;
    z-index: 1000;
    &:hover {
      opacity: 0.9;
    }
  }
  &__error-text {
    color: var(--white);
    font-size: 14px;
  }
}
