@import "../mixins/index.scss";

:root {
  --black: #000000;
  --black-sec: #111111;
  --white: #ffffff;
  --green: #2ff8b9;
  --gray: #E4E4E4;
  --begie: #edeae7;
  --red: #c84f4f;
  --orange: #fa6c3d;
  --violet: #6461e2;
}

html {
  scroll-behavior: smooth;
}

.container {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1320px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;

  @include xl {
    max-width: initial;
    width: 100%;
  }
}

body {
  font-family: "Nunito", Arial;
  color: var(--white);
  font-size: 16px;
}

section {
  scroll-margin-top: 120px;
}

p {
  font-weight: 700;
}

h1,
.h1 {
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 92px;
  @include sm {
    font-size: 42px;
  }
}

h2 {
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--green);
  margin-bottom: 40px;
  line-height: 42px;
  @include md {
    margin-bottom: 20px;
  }
  @include sm {
    font-size: 30px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

.text-green {
  color: var(--green);
}

.btn {
  color: var(--white);
  font-weight: 700;
  background: linear-gradient(270deg, #7900da 0%, #ae46ff 100%);
  border-radius: 25px;
  text-transform: uppercase;
  padding: 14px 24px;
  transition: opacity 0.3s;
  font-size: 16px;
  display: inline-flex;
  gap: 16px;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
}
.btn-primary {
  @extend .btn;
  background: initial;
  background-color: #479be9;
}

.text-center {
  text-align: center;
}

.chevron::before {
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 12px;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}
