
@import "../../styles/mixins/index.scss";

.mint {
    background-image: url("../../assets/images/mint/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 82px 0;
    position: relative;
  
    &:before {
      background: linear-gradient(0deg, transparent, #070517);
      top: 0;
      content: "";
      height: 59px;
      position: absolute;
      width: 100%;
    }
    &__logo {
      margin: 0 auto 26px auto;
      display: block;
    }
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 40px;
      @include lg {
        gap: 20px;
      }
      @include md {
        flex-direction: column;
        text-align: center;
      }
      & > div {
        width: 300px;
        @include lg {
          width: calc(100% / 3 - 20px);
        }
        @include md {
          width: calc(100% / 2 - 20px);
        }
      }
    }
    &__price {
      font-size: 50px;
      font-weight: 700;
      @include md {
        font-size: 26px;
      }
      &_text {
        font-weight: 700;
        font-size: 24px;
      }
    }
    &__title {
      color: var(--black);
      font-weight: 700;
      margin-bottom: 20px;
    }
    &-total {
      text-align: right;
      &__counter {
        margin-bottom: 30px;
        display: flex;
        justify-content: end;
        @include md {
          justify-content: center;
        }
      }
      &__price {
        font-size: 24px;
        font-weight: 700;
      }
      @include md {
        p {
          text-align: center;
        }
      }
    }
  }